<template>
  <el-config-provider :locale="locale">
    <router-view/>
  </el-config-provider>
</template>
<script setup>
import { ElConfigProvider } from 'element-plus';
import epZhCn from 'element-plus/dist/locale/zh-cn.mjs';
import epEn from 'element-plus/dist/locale/en.mjs';
import $ls from '@/utils/localStore';
let sLocale = $ls.get('locale');
const locale = sLocale? sLocale === 'zh-CN' ? epZhCn : epEn : epZhCn;
</script>
<style lang="scss">

</style>
