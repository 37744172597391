export default {
    signin: "Login",
    signup: "Sign Up",
    time_limited: "Time-Limited",
    lable_recommended: "Recommended",
    label_enterprise: "Enterprise",
    menu: {
        item_1: "AI Translation",
        item_2: "AI-assisted Translation",
        item_3: "AI Toolkit",
        item_4: "Price",
        item_5: "Community",
        item_6: "A Letter",
    },
    index: {
        banner_1_title: "Born for Language Lovers",
        banner_1_btn: "Free Trial",
        banner_1_btn_2: "Join Waitlist",
        banner_2_title: "LanguageX Wins 2022 WMT International Machine Translation Competition",
        banner_2_btn: "View More",

        model_1_title: "Powerful AI Translation Designed for Language Service",
        model_1_desc: "Find the most suitable translation engine in the world, and if there isn't one, train one yourself.",
        model_1_item_1_title: "Recommended Machine Translation",
        model_1_item_1_desc: "Finding the right engine is as important as finding the right initial translator. Intuitive comparisons, customizable terminology-making machine translation understand you better.",
        model_1_item_2_title: "Train your own translation engine",
        model_1_item_2_desc: "Once you had a translation memory, now you have a translation engine. One-click upload, automatic data cleansing, and training your own engine.",

        model_2_title: "AI-assisted Translation",
        model_2_desc: "Focus on the language you love without worrying about complex translation technology",
        model_2_item_1_title: "Interactive Translation",
        model_2_item_1_desc: "With real-time editor previews, interactive translations and QA check, all you need to do is focus on the language you love!",
        model_2_item_2_title: "Translation Project Management",
        model_2_item_2_desc: "Team management, teamwork, customized translation process, task distribution in batches",

        model_3_title: "AI Toolkit",
        model_3_desc: "Minimalist, intelligent, and handy AI translation tools",
        model_3_comesoon: "Coming Soon",
        model_3_item_1_title: "AI Polisher",
        model_3_item_1_desc: "Generative AI-based, your on-the-go native language touch-up expert!",
        model_3_item_2_title: "Corpus Aligner",
        model_3_item_2_desc: "Industry-leading alignment algorithm, brand new drag-and-drop alignment experience. AI makes corpus alignment easier.",
        model_3_item_3_title: "MT Detector",
        model_3_item_3_desc: "Intelligently recognizing machine translation, effectively identifying translation quality problems.",
        model_3_item_4_title: "Terminology Extractor",
        model_3_item_4_desc: "Intelligently extracting terms from bulk files and swiftly editing to create your termbases",
        model_3_item_5_title: "QA Checker",
        model_3_item_5_desc: "Not only provide rule-based QA, but also intelligent checking based on semantics.",
        model_3_item_6_title: "AI Dubber",
        model_3_item_6_desc: "Within one click, translating and dubbing both are settled.",
        model_3_item_7_title: "AI Evaluation",
        model_3_item_7_desc: "AI evaluation on MT, thus recommending the most suitable engine.",

        model_4_title: "Price",
        model_4_tab_1: "Membership",
        model_4_tab_2: "Characters",
        model_4_desc_1: "Document translation, text translation, and AI toolkit calculate characters according to the original text. Membership Service: Characters are valid within one month/half a year/one year from the purchase date, resetting monthly without carryover (",
        model_4_desc_link: "view more",
        model_4_desc_2: "). LanguageX reserves the right of final interpretation.",
        //feature_1
        model_4_item_1_title: "FREE",
        model_4_item_1_feature_1: "12 universal engines",
        model_4_item_1_feature_2: "6 official personalized engines",
        model_4_item_1_feature_3: "4 AI toolkits",
        model_4_item_1_feature_3_desc: "AI Polisher, AI Evaluation, Corpus Aligner, Large Model Agent Translation",
        // model_4_item_1_feature_4: "AI Polisher",
        model_4_item_1_feature_5: "50,000 free characters per month",
        model_4_item_1_feature_6: "File size: 10MB",
        model_4_item_1_feature_7: "Free trial usage of AI Video Dubbing",
        model_4_item_1_btn: "Forever Free",
        //feature_2
        model_4_item_2_title: "PRO",
        model_4_item_2_feature_1: "All Free features",
        model_4_item_2_feature_1_desc: "6 large model translations such as ChatGPT, Gemini, Kimi, etc.",
        model_4_item_2_feature_2: "9 vertical domain engines",
        model_4_item_2_feature_3: "Terminology customization for all engines",
        model_4_item_2_feature_4: "Create your own personalized engines",
        model_4_item_2_feature_5: "5 AI toolkits",
        model_4_item_2_feature_5_desc: "AI Polisher, Corpus Aligner, MT Detector, AI Evaluation, AI Video Dubbing (3 times per month), Large Model Agent Translation",
        model_4_item_2_feature_6: "500,000 free characters per month",
        model_4_item_2_feature_7: "File size: 50MB",
        model_4_item_2_feature_8: "PDF supported",
        model_4_item_2_price_1: "￥29",
        model_4_item_2_price_1_crossed: "￥59",
        model_4_item_2_price_1_expires: "Month",
        model_4_item_2_price_2: "￥159",
        model_4_item_2_price_2_crossed: "￥329",
        model_4_item_2_price_2_expires: "Half Year",
        model_4_item_2_price_3: "￥299",
        model_4_item_2_price_3_crossed: "￥599",
        model_4_item_2_price_3_expires: "Year",
        model_4_item_2_btn: "7-day Free Trial",
        //feature_3
        model_4_item_3_title: "Enterprise Version",
        model_4_item_3_feature_1: "All PRO features",
        model_4_item_3_feature_2: "5000,000 free characters per month",
        model_4_item_3_feature_3: "All AI toolkits available (AI Video Dubbing available up to 10 times per month)",
        model_4_item_3_feature_4: "4 personalized translation engines",
        model_4_item_3_feature_5: "File size: 100M",
        model_4_item_3_feature_6: "Supported 50 files for one project",
        model_4_item_3_feature_7: "Project management",
        model_4_item_3_feature_8: "Up to 10 team members",
        model_4_item_3_btn: "7-day Free Trial",
        model_4_item_3_price_1: "￥299",
        model_4_item_3_price_1_crossed: "￥599",
        model_4_item_3_price_1_expires: "Month",
        model_4_item_3_price_2: "￥12000",
        model_4_item_3_price_2_crossed: "￥5999",
        model_4_item_3_price_2_expires: "Year",
        //feature_4
        model_4_item_4_title: "Education Version",
        model_4_item_4_feature_1: "All PRO features",
        model_4_item_4_feature_2: "2,000,000 free characters per month",
        model_4_item_4_feature_3: "All AI toolkits available (AI Video Dubbing available up to 10 times per month)",
        model_4_item_4_feature_4: "4 personalized translation engines",
        model_4_item_4_feature_5: "Larger files",
        model_4_item_4_feature_6: "Supported more files for one project",
        model_4_item_4_feature_7: "Project management",
        model_4_item_4_feature_8: "Up to 50 students",
        model_4_item_4_btn: "Contact Us",

        model_4_char_btn_1: "Buy Now",
        model_4_char_btn_2: "Contact Us",

        model_4_char_1_title: "100,000 characters",
        model_4_char_1_price: "¥25",
        model_4_char_1_desc: "equals to ¥2.5 per 10,000 characters",
        model_4_char_2_title: "500,000 characters ",
        model_4_char_2_price: "¥99",
        model_4_char_2_desc: "equals to ¥1.98 per 10,000 characters",
        model_4_char_3_title: "1000,000 characters",
        model_4_char_3_price: "¥139",
        model_4_char_3_desc: "equals to ¥1.39 per 10,000 characters",
        model_4_char_4_title: "10,000,000 characters",
        model_4_char_4_price: "¥1251",
        model_4_char_4_desc: "equals to ¥1.251 per 10,000 characters", 
        model_4_char_5_title: ">100 million characters",    

        model_5_title: "User Comments",
        model_5_experience_1: "{year} years of translation",
        model_5_experience_2: "Lecturer of Translation Technology in CUHK ",
        model_5_experience_3: "{year} years of PM experience",
        model_5_item_1_user: "Bobbie Chen",
        model_5_item_1_desc: "The user editing interface is super easy to navigate. The interactive translation feature works like magic – it tweaks sentence structure in real-time as edits are made, saving me loads of time on revisions. Looking at the whole picture, LanguageX stands out as the top-notch translation software for new translators out there.",
        model_5_item_2_user: "Tianbing Wei",
        model_5_item_2_desc: "A translation software designed for young translators, with Morandi-inspired color schemes, just-right animations, foolproof operational logic, and personalized mini-engines, turning daily translation work into a life simulation game. I've always believed that future translation software, besides being powerful, should also have warmth, be capable of growth, be frequently used and constantly new, and now it's here—LanguageX!",
        model_5_item_3_user: "Flora Wang",
        model_5_item_3_desc: "I fully support the philosophy of LanguageX that 'Let machines take the work of machines, while humans take their duty as humans'. It's more like a 'bosom partner', which can not only help with the basic and mechanical translation, but also provide ideas and inspiration for dealing with complex translation tasks. It inspires translators to create high-quality translations that go beyond what machines can do. I strongly recommend you to try it.",
        model_5_item_4_user: "HU Ke",
        model_5_item_4_desc: "LanguageX has three distinct advantages. First, user-friendly interface: Compared to many traditional CAT tools, LanguageX's interface is clear and intuitive, which is easy for newcomers to get started. Second, comprehensive functions: Functions like comparing different translation engines and training personal engines can effectively help translators improve efficiency. Third, high adaptability: As a cloud-based platform, LanguageX is more suitable for young users.",
        model_5_item_5_user: "Sherry Sui",
        model_5_item_5_desc: "The interface of LanguageX is clear and user-friendly at a glance, which makes it easy to get started. It integrates a complete process of online project allocation, proofreading, QA and so on. Moreover, it integrates several major and popular translation engines, making it possible for users to choose the most suitable one.  It is very convenient, requiring only a link to achieve remote work.",
        model_5_item_6_user: "Vicky Li",
        model_5_item_6_desc: "LanguageX offers a wide range of translation engines, and the user can even customize their own translation engines to suit for different projects, allowing us to effectively and precisely handle projects of different types and fields. There are many tools that can be utilized throughout the entire translation process. By integrating translation and technology, LanguageX has created an all-in-one intelligent platform for translation.",

        model_6_title: "Join us",
        model_6_left_item_1: "We are enthusiasts of language and technology.",
        model_6_left_item_2: "We are translators between languages and technologies.",
        model_6_left_item_3: "We are hackers and painters.",
        model_6_left_item_4: "We are committed to creating the ultimate AI tools for language lovers.",
        model_6_right_item_1: "Translation is about Art.",
        model_6_right_item_2: "Translators are cross-language writers.",
        model_6_right_item_3: "Let machines take the work of machines, while humans take their duty as humans.",

        model_7_title: "Our Customer",

        footer_product: "Product",
        footer_product_1: "AI Translation",
        footer_product_2: "AI-assisted Translation",
        footer_product_3: "AI Toolkit",
        footer_product_4: "Translator Community",
        footer_product_5: "LanguageX API",
        footer_help: "Help Center",
        footer_help_1: "What is LanguageX",
        footer_help_2: "Guide to AI-assisted Translation",
        footer_help_3: "Guide to AI Toolkit",
        footer_help_4: "FAQ",
        footer_help_5: "Guide to TMS Enterprise",
        footer_aboutus: "About",
        footer_aboutus_1: "Contact Us",
        footer_agreement: "User Agreement",
        footer_agreement_1: "Service Agreement",
        footer_agreement_2: "Privacy Policy",
        footer_contact_1: "Official Account",
        footer_contact_2: "User Group",
    }
}