import { createApp } from 'vue'
import animated from 'animate.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/element-var.scss'
import './assets/css/common.css'
const app = createApp(App)

app
    .use(animated)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(i18n)
    .mount('#app')
