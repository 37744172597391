import { createI18n } from 'vue-i18n'
import $ls from '@/utils/localStore';
import zhCN from './zh-CN';
import enUS from './en-US';



const messages = {
  'zh-CN': { ...zhCN, },
  'en-US': { ...enUS,},
}
// console.log('message::', messages);
let lang = navigator.language;
if(lang.indexOf('zh') > -1){
  lang = 'zh-CN';
}else if(lang.indexOf('en') > -1){
  lang = 'en-US';
}else{
  lang = 'zh-CN';
}
if($ls.get('locale')){
  lang = $ls.get('locale');
}

const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages
})

export default i18n